@import url('https://fonts.cdnfonts.com/css/matricha');
.scroll-snap{
    scroll-snap-type: y mandatory;
}

.Team-main-bg{
    background: url("../images/backgrounds/animatedBackground.gif") center center;
    background-size: cover;
}

#section-scroll{
    scroll-snap-align: center;
}

.w-65{
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.col-4{
    display: flex;
    justify-content: center;
}
.card{
    border: 2px solid  #7944a4;
    background-color: rgba(159,105,198, 0.3);
    border-radius: 10px 10px;
    overflow: hidden;
}

.card-img-top{
    border-bottom: 1px solid  #7944a4;
}

#team-leader-title{
    /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
    
    font-weight: 600 ;
}

#team-about-us{
    text-align: center;
    align-items: center;
    /* background-color: rgba(159,105,198, 0.3);
    border: 5px dotted #7944a4;
    border-radius: 10px; */
}

#team-about-us h1, .justify-content-end h1, .justify-content-start h1{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    width: fit-content;
    border: 0px !important;
    font-weight: 500;
    text-shadow: 5px 5px rgba(121,80,171, 0.4);
    /* font-size: 50px !important */

}

.justify-content-center h1#team_member{
    color:white;
    text-shadow: 7px 7px rgba(159,105,198, 0.4);
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    width: fit-content;
    border: 0px !important;
    font-weight: 500;

}

#team-section-separation{
    background: url("../images/backgrounds/animatedBackground.gif") center center;

}

.bg-purple{
    background-color: rgba(86,23,176,1);
}

.separator{
    transform: translateY(-99%);
}

.departments {
    background-color: black;
    position: relative;
    /* background: url("../images/backgrounds/animatedBackground.gif") center center;
    background-size: cover;
    background: rgb(135,96,193);
    background: linear-gradient(0deg, rgba(135,96,193,1) 0%, rgba(2,0,32,1) 100%); */
    height: 100%;
}

.departments1 {
    /* background-color: black; */
    position: relative;
    /* background: url("../images/backgrounds/animatedBackground.gif") center center;
    background-size: cover; */
    background: rgb(68,107,135);
    background: linear-gradient(0deg, rgba(68,107,135,1) 0%, rgba(38,39,39,1) 100%);
    height: 100%;
}
.departments1#bg-reverse{
    background: rgb(38,39,39);
    background: linear-gradient(0deg, rgba(38,39,39,1) 0%, rgba(68,107,135,1) 100%);
}
.upper-bar{
    width:50%;
    position: absolute;
    top:0;
    left: 0;
    display: flex;
    justify-content: left !important;
    background-color: rgba(104,48,175,255);
    box-shadow: 0.5rem 0.5rem   rgba(104,48,175,0.4);

}

h1.big-title{
    font-size:6rem;
}

.dp-leader{
    display: flex;
    width: 40%;
    max-width: 550px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: auto;
    /* overflow: hidden; */
    border-radius: 10px 10px;
    /* border: 1px solid  #7944a4; */
}



.dp-leader > h3{
    font-size: 1em;
    margin-top: 5px;
    width: 100%;
    text-align: center;
    justify-content: center;
    
}
.overlay > h4{
    font-size: 1.4vmin;
    width:auto;
    max-width: 50%;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
}


.profile-container{
    position: relative;
    width: 60% !important;
    max-width: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-container > .profile-img{
    width: 100%;
    display: block;
    box-shadow: 0.4rem 0.4rem  rgba(159,105,198, 0.3);
    border: 1px solid rgba(255,255,255, 0.4);
    /* border-radius: 11.23px 11.23px 0 0; */

}

.overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(52,7,89, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
   
}

.profile-container:hover .overlay {
    opacity: 1;
  }

.shaped-bg{
    width:100%;
    /* border-radius: 0% 0% 0 70% ; */
}

.h-member-role {
    font-size: 1vw!important;
    color: white;
}

.h-member-name{
    font-size: 1vw !important;
    font-weight: 600;
    margin-top : 1rem !important;
    text-transform: none !important;
    
}
.our-team{
    text-align: center;
    overflow: hidden;
    position: relative;
    background: #5617b0;
    border-radius: 10px 10px;
    max-width: 300px;
}
.our-team img{
    width: 100%;
    height: auto;
    transition: all 0.5s ease-in-out 0s;
    
}
.our-team:hover img{ transform: scale(1.2); }
.our-team .social{
    list-style: none;
    padding: 30px 15px;
    margin: 0;
    background: #5617b0;
    border-bottom-right-radius: 50px;
    max-height: 60%;
    position: absolute;
    top: 0;
    left: -50%;
    transition: all 0.4s ease-in-out 0s;
}
.our-team:hover .social{ left: 0; }
.our-team .social li{ display: block; }
.our-team .social li a{
    display: block;
    padding: 0.2vw 0;
    font-size: 18px;
    color: #fff;
    transition: all 0.3s ease-in-out 0s;
}
.our-team .social li:first-child a{ padding-top: 0; }
.our-team .social li:last-child a{ padding-bottom: 0 !important; }
.our-team .social li a:hover{ color: #000; }
.our-team .team-content{
    width: 100%;
    padding: 15px 10px;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    left: 0;
}

.our-team .title{
    font-size: 22px !important;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
    margin: 0 0 5px 0;
}
.our-team .post{
    display: block;
    font-size: 14px !important;
    color: #0facf3;
}

@media only screen and  (max-width: 990px){
    .dp-leader{
        width: 100%;
    }
    
    .row>.col-8, .row>.col-4{
        width: 100%;
    }

    .col-12>.row{
        display: flex !important;
        justify-content: center !important;
        width: 100% !important;
    }
    .col-12>.row>.col-6{
        display: flex;
        justify-content: center;
        width: 80% !important;
    }
    .col-7, .col-5{
        width: 50% !important;
    }
    .row>.big-title{
        font-size: 4vw !important;
    }
    .col-6>.align-items-center{
        width: 100%;
    }

    .our-team{ margin-bottom: 30px; }
    .our-team .title{ font-size: 18px !important;}
    .our-team .post{ font-size: 12px !important;}
}
@media only screen and  ((max-width: 1200px) and (min-width: 991px)) {

    .row>.big-title{
        font-size: 4vw !important;
    }
    .our-team .social{
        padding-top: 5px;
        padding: 5px 2.5px;
    }
    .fab>.w-75{
        width: 50% !important
    }
    .our-team .title{ font-size: 12px !important;}
    .our-team .post{ font-size: 11px !important;}
    
}

@media only screen and  ((max-width: 620px) ) {
    .col-5{
        display: none;
    }
    .col-6>.row{
        width: 100% !important;
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
    }
    .col-12>.row>.col-6{
        width: 100% !important;

    }

}

/* adio social pt telefoane */


@media only screen and (max-width: 990px){
    .our-team{ margin-bottom: 30px; }
    .our-team .title{ font-size: 11px !important;}
    .our-team .post{ font-size: 10px !important;}
}
@media only screen 
  and (max-device-width: 520px) 
  and (max-device-height: 812px) { 
    .social{
        display: none !important;
    }
}
.sp2-schedule-container{
    position: relative;
    width: 500px;
    height: fit-content;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

}

#sp2-schedule-container{
    padding: 0;
    height: 100px;
}
.sp2-schedule-container-title{
    font-size: 27px !important;
    margin-left: 5%;
    margin-top: 10px;
    
}

.sp2-schedule-container > div{
    height: 230px !important;
    border-radius: 20%;
}

.sp2-schedule-ul{
    margin-right: 5%;
}

.sp2-schedule-ul>li{
    color: white;
    font-size: 14px !important;
    justify-content: start; 
    text-align: start;
    font-weight: bold;
}


#c8760C1{
    background-color: #8760C1;
    height: 300px !important;
    width: 300px!important;
    border-radius: 15px;
}
#c5A15C1{
    background-color: #5A15C1;
    height: 300px !important;
    width: 300px!important;
    border-radius: 15px;

}

.sp2-section-title{
    font-size:50px !important;
    font-weight: bolder;
    text-align: center;
}

/*========= FOOOTER 2024 =========*/

.horizontal-delimiter{
    margin-right:0px !important;
    margin-left:0px !important;
    background-color: whitesmoke;
    color: whitesmoke;
    height: 3px !important;
    font-size: 10px;
    font-weight: 900;
}
.footer-logo{
    position: relative;
    width: 12.5vw !important;
    object-fit: contain;
    margin-left: 0;
}

.clasic-input{
    background: transparent;
    font-size: smaller;
    height: 3vw;
    padding: 0.5vw;
    border: 1px solid white;
    margin-right: 20px !important;
}
.clasic-input:focus{
    color: white;
}
#footer-content{
    flex-wrap: wrap;
}

#footer-subscribe-news{
    height: 3vw;
    font-size: small !important;

}

.footer-links-categ{
    font-weight: 800;
    color: white !important;
}

.footer-links-page{
    color: white !important;
    font-weight: 500 !important;
}

iframe.footer-maps{
    width:100%;
    height: 400px;
}




@media only screen and (max-width: 1660px ) and (min-width: 501px)   {

    .hero-button-transparent{
        margin-right: 0 !important;
    }
    #awards_section > .justify-content-start{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }

    .event_card_description::before{
        filter: blur(8.5vw);
    }
    .footer-logo{
        width: 25vw !important;
    }

    h6.footer-links-categ{
        font-size: small !important;
    }
    h6.footer-links-page{
        font-size: small !important;
    }

    
  }

  @media only screen and (max-width: 893px)   {

    #footer-links{
        width: 100% !important;
        justify-content: space-between !important;
        
    }

    #footer_input_news{
        justify-content: start !important;
        grid-gap: 1px;
    }

    .w-50#footer_search{
        width: 100% !important;
    }

    p#p-hero2{
        font-size: 2.2vw !important;
    }

    #footer-links{
        padding: 0 !important;
        margin-bottom: 15px;
    }

    .clasic-input, #footer-subscribe-news{
        height: 4vw;
        font-size: 2vw !important;
    }
    h6.footer-links-categ{
        font-size: small !important;
    }
    h6.footer-links-page{
        font-size: small !important;
    }
}

@media only screen and (max-width: 500px)   {

    .footer-links-categ{
        font-size: 2.5vw !important;
    }
    
    .footer-links-page{
        font-size: 2.5vw !important;
    }

    .footer-logo{
        width: 50vw !important;
    }
    
    p#p-hero2{
        font-size: 2.2vw !important;
    }
}

/*========= FOOOTER 2024 =========*/








/*@media only screen and (min-width: 1440px) {
    .sp2-schedule-container{
        width: 800px;
        margin-bottom: 150px;
    }
    
    .sp2-schedule-container-title{
        font-size: 32px !important;
        margin-left: 5%;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    
    .sp2-schedule-container > div{
        height: 340px !important;
    }
    
    .sp2-schedule-ul{
        margin-right: 5%;
    }
    
    .sp2-schedule-ul>li{
        color: white;
        font-size: 21px !important;
        justify-content: start; 
        text-align: start;
        font-weight: bold;
    }
    
    
    #c8760C1{
        background-color: #8760C1;
        height: 480px !important;
        width: 480px!important;
        border-radius: 15px;
    }
    #c5A15C1{
        background-color: #5A15C1;
        height: 480px !important;
        width: 480px!important;
        border-radius: 15px;
    
    }
    
    h1.sp2-section-title{
        font-size:40px !important;
        font-weight: bolder;
        text-align: center;
    }

}*/


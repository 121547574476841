

.home-body{
    text-align: center;
    background-color: black;
    overflow: hidden !important;
}

#home-header{
    background: url("../../images/backgrounds/vodafone.JPG") center center ;
    background-size: cover;
}


.sp-header::-webkit-scrollbar{
    display: none;
}

.home-content{
    width: 25%;
    min-width: fit-content;
    max-width: 400px !important;
    z-index: 4;
}

.header-button{
    color: white;
    font-weight: 600;
    width: 45%;
    max-height: fit-content;
    border: 2px solid white;
    background: rgba(0,0,0,0);
    padding: 10px;
    font-size: 1.4vw !important;
    position: relative;
}

#glowing-button-margin{
    border-style: solid;
    border-width: 2x;
    border-image-source: linear-gradient(to left, #FF4A40, #6B0FF6);
    -webkit-border-image-slice: 2;
    border-color: transparent;
    border-image-slice: 1;
}

section{
    padding: 4%;
}

section#hero-home-section{
    position: relative !important;
    display: flex; 
    flex-direction: row;
    z-index :2;
}

/* section#hero-home-section::before{
    content: "";
    position: absolute;
    width:100%;
    height: 25vh;
    background: rgb(169,119,243, 0.3);
    background: linear-gradient(0deg, rgba(169,119,243,1) 0%, rgba(255,95,86,0.27) 100%);
    filter: blur(10vmax);
    border-radius: 100%;
    top:-16vmax;
    z-index: 2;
    left: 0;
} */



div#hero1{
    width: 50%;
    justify-content:start ;
    align-items: center;
}

div#hero2{
    width: 50%;
    justify-content: start;
    align-items: center;
}

h1#heading{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 800 !important;
    font-size: 3vw !important;
    text-align: start;
    color: white;
    text-shadow: none !important;
}

h2#heading{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700 !important;
    font-size: 2vw !important;
    text-align: start;
    color: white;
    text-shadow: none !important;
}

h1#heading-glowing{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 800 !important;
    font-size: 2.9vw !important;
    text-align: start;
    background-image: linear-gradient(90deg,#FF4A40 , #6B0FF6 80% ) ;
    background-clip: text;
    color: transparent;
}

div#hero-buttons-container{

    width: fit-content;

}

button.hero-button-purple{
    border: 1px solid grey ;
    background-color: #6B0FF6;
    color: white;
    padding-left: 1.2vw;
    padding-right: 1.2vw;
    padding-top: 3%;
    padding-bottom: 3%;
    font-size: 0.8vw !important;
    font-weight: 600;
    margin-right: 1vw;
}

button.hero-button-transparent{
    border: 1px solid grey ;
    background: transparent;
    color: white;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 0.6vw;
    padding-bottom: 0.6vw;
    font-size: 0.8vw !important;
    font-weight: 600;
    margin-right: 1vw;
}
.mr-0 button.hero-button-transparent{
    margin-right: 0 !important;
}
p#p-hero2{
    padding: 0;
    z-index: 10 !important;
    color:white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1vw !important;
    font-weight: 100 !important;
    text-align: start;
}

#p-relative{
    position: relative;
}
section#team-home-section{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: start !important;

}

h6.h6-home{
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500 !important
}

h5.home{
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300 !important
}

h1.h1-light{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 550 !important;
    font-size: 3vw !important;
    text-align: start;
    color: white;
    text-shadow: none !important;
}

div.home-member-card{
    position: relative;
    margin-top: 80px;
    width: 40%;

    /*
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.8px);
    -webkit-backdrop-filter: blur(5.8px);
    border: 1px solid rgba(255, 255, 255, 0.05);
    */

}
div.home-member-card#view-more{
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    text-align: center;
    
}

/* div.home-member-content:hover, div.home-member-card#view-more:hover{
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.8px);
    -webkit-backdrop-filter: blur(6.8px);
    border: 1px solid rgba(255, 255, 255, 0.05);
    transition: ease 0.1s;
} */

div.home-member-card#view-more:hover{
    cursor: pointer;
}
img.home-member-image{
    aspect-ratio: 1/1;
    width: 40%;
    border-radius: 100%;
    margin-bottom: 30%;
}


p#p-member-description{
    font-size: 0.8vw !important;
    text-align: justify;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300 !important;
    margin-top: 2vw;
}

h6#h6-member-name{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.1vw !important;
    font-weight: 700 !important;
    color:white;
}

#about-us-section{
    overflow-y: visible !important;

    z-index: 4;
}

h6#h6-member-title{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.9vw !important;
    font-weight: 300 !important;
    color:white;
}

.fa-gradient-pink-purple{
    margin-top: 2vh;
    margin-right: 1vw;
    z-index: 3;

    font-size: 1.5vw !important;
    fill: url(#blue-gradient);
}

.fa-gradient-pink-purple:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.home-member-content{
    position: relative;
    z-index: 3;
}

.home-member-content::before{
    content: "";
    position: absolute;
    width:70%;
    max-width: 70%;
    min-height: 80%;
    background: rgb(169,119,243, 0.3);
    background: linear-gradient(0deg, rgba(169,119,243,1) 0%, rgba(255,95,86,0.42) 100%);
    filter: blur(110px);
    border-radius: 100%;
    top: 0;

    left: 0;
}
.offcanvas{
    transition: ease 0.3s !important;
}

#arrows{
    margin-left: 0.5vw !important;
    font-size: 1.2vw !important;
}
#arrows:hover{
    cursor: pointer;
}

div.dot-home-team{
    aspect-ratio: 1/1 !important;
    width: 0.6vw;
    margin-top: auto;
    margin-right: 0.5vw !important;
    background-color: grey;
    border-radius: 10px;
 
}

div.dot-home-team#active{
    background-color: #6B0FF6;
}

.team-carousel{
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  height: fit-content;
  opacity: 0;
  transition: all 0.3s linear;;
}

div.activeSlide {
    opacity: 1;
    transform: translateX(0);
}

div.lastSlide {
    transform: translateX(-100%);
}

div.nextSlide {
transform: translateX(100%);
}

div.carousel-inner{
    overflow: visible !important;
}

#about-us-picture{
    position: relative;
    aspect-ratio: 4/5 !important;
    z-index: 7;
}
#about-us-image-div{
    position: relative;

}
#about-us-image-div::before{
    content: "";
    position: absolute;
    width:75%;
    height: 80%;
    background: rgb(169,119,243, 0.3);
    background: linear-gradient(180deg, rgba(169,119,243,1) 0%, rgba(255,95,86,0.27) 100%);
    filter: blur(120px);
    border-radius: 100%;
    top:0vh;
    z-index: 5;
    z-index: 6 !important;
    overflow-y: visible !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
}

#about-us-content{
    max-width: min(100%, 700px);
}

.hover-glowing{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 800 !important;
    padding-bottom: 0.5rem;
    position:relative;
    width: fit-content;
    cursor: pointer;
    font-size: 2.8vw !important;
    text-align: start;
    position: relative;
    display: inline-block;
    font-weight: 800;
    color: white;
    overflow: hidden;
    background: linear-gradient(to right, #FF4A40, #6B0FF6 50%, white 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 0.27s ease;
    text-decoration: none;
}

.hover-glowing:hover{
    background-position: 0 100%;
    border-style: solid;
    border-width: 2x;
    border-image-source: linear-gradient(to left, #6B0FF6 , #FF4A40);
    -webkit-border-image-slice: 2;
    border-color: transparent;
    border-image-slice: 1;
    border-top: 0px ;
    border-right: 0px;
    border-left: 0px;

}
.home-project-container{
    max-width: min(100%, 1440px);
}

.img-project{
    z-index: 2;
    object-fit: fill;
}

div#img-project-div{
    position: relative;
    display: flex;
    max-height: 80vh;
    z-index: 3;
}

div#img-project-div::before{
    content: "";
    position: absolute;
    width:100%;
    height: 25vh;
    background: rgb(169,119,243, 0.3);
    background: linear-gradient(0deg, rgba(169,119,243,1) 0%, rgba(255,95,86,0.27) 100%);
    filter: blur(110px);
    border-radius: 100%;
    top: -5vh;
    left: 0;
    z-index: 1;
}

div#img-project-div::after{
    content: "";
    position: absolute;
    width:100%;
    height: 25vh;
    background: rgb(169,119,243, 0.3);
    background: linear-gradient(0deg, rgba(169,119,243,1) 0%, rgba(255,95,86,0.27) 100%);
    filter: blur(110px);
    border-radius: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
}


button.hero-tag-purple{
    border: 2px solid grey ;
    background-color: #6B0FF6;
    color: white;
    padding-inline: 0.7vw;
    padding-block: 0.2vw;
    font-size: 0.57vw !important;
    font-weight: 600;
    cursor: default;
}

h4#heading{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 650 !important;
    font-size: 1.6vw !important;
    text-align: start;
    color: white;
    text-shadow: none !important;
}

h6#heading{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500 !important;
    font-size: 0.8vw !important;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: white;
    text-shadow: none !important;
    cursor: pointer;
}

p#p-hero3{
    display: flex;
    margin:0 !important;
    justify-content: start;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500 !important;
    font-size: 0.8vw !important;
    color: white;
    align-items: center;
    text-align: start;
}

.events_row{
    max-width: fit-content;
    margin-top: 5vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    row-gap: 1vh;
    column-gap: 1vh;
}



.event_card{
    /* border: 4px solid white; */
    width: 32%;
    position: relative;
    max-width: 700px;
    min-width: 400px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.event_card_body{
    /* border: 2px solid white; */
    width: 100%;
    height: 100%;
}

.event_card_header{
    position: relative;
    width: 100%;
    aspect-ratio: 3/2;
    display: block;
    object-fit: contain;

}

.event_card_img{
    display: block;
    width: 100% !important;
    height: 100%;
    position: relative !important;
    z-index: 2;
}

.event_card_img_overay{
    content: "";
    position: absolute;
    width:100%;
    height: 100%;
    background: rgb(169,119,243, 0.3);
    background: linear-gradient(0deg,  rgba(255,95,86,0.79) 0%, rgba(169,119,243,1) 100%);
    opacity: 0.48;
    bottom: 0;
    left: 0;
    z-index: 3;
}

.event_card_date{
    background-color: white;
    width: 18%;
    position: absolute;
    top: 4%;
    right: 2%;
    aspect-ratio: 1/1 !important;
    color: black ;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 4;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


}

.event_card_date>h6{
    font-size: 0.9vw !important;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
    color: black !important;
}
.event_card_date>h3{
    font-size: 1.5vw !important;
    font-weight: 800;
    color: black !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.event_card_body > * > .hero-tag-purple{
    padding-inline: 1.4vw;
}


.event_card_description{
    position: relative;

    display: flex;
    flex-direction: column;
    text-align: start !important;
    align-items: start;
}


.event_card_description::before{
    content: "";
    position: absolute;
    width:70%;
    min-height: 70%;
    background: rgb(169,119,243, 0.3);
    background: linear-gradient(0deg, rgba(169,119,243,1) 0%, rgba(255,95,86,0.42) 100%);
    filter: blur(6.5vw);
    border-radius: 30%;
    top: 0;
    z-index: 1 !important;
    left: -20%;
}

h4.event_card_title{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-weight: 700 !important;
    color: white !important;
}

a.event_card_location{
    text-decoration: inherit;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: inherit;
    cursor: auto;
    margin-left: 0;
    color: white;
    text-align: start;
    font-size: medium;
    text-decoration: underline white !important;

}

a.event_card_location:hover{
    cursor: pointer;
}

p.event_card_location{
    text-decoration: inherit;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: inherit;
    cursor: auto;
    margin-left: 0;
    color: white !important;
    text-align: start;
    font-size: medium;
}


.border-0{
    border: none !important;
}



.glowing-frame{
    background: transparent;
    height: 240px;
    min-height: 120px !important;
    aspect-ratio: 3/2 !important;
    display: flex;
    justify-content:center;
    align-items: center;
    border-style: solid;
    border-width: 2x;
    -webkit-border-image-slice: 1;
    border-color: transparent;
    border-image-slice: 1;
}

.glowing-frame > img{
    object-fit: contain;
    height: 50%;
}

#glow-frame-0{
    line-break: auto;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #FF4A40, #6B0FF6);

}

#glow-frame-180{
    border-image-slice: 1;

    border-image-source: linear-gradient(to right, #FF4A40, #6B0FF6) !important;

}

#sponsors_section{
    max-width: 100%;
    margin-top: 5vh;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    row-gap: 1vh;
    column-gap: 1vh;

}





@media only screen and (max-width: 1200px)   {
  
 
    /* div.home-member-card{
        width: 25%;
    }
    
    #about-us-section{
        flex-direction: column !important;
    } */
   
  }



  @media only screen and (max-width: 1440px) and (min-width: 894px)   {
    .events_row{
        max-width: 870px !important;

    }
   
    a, p, h4{
        z-index: 10 !important;
     }
  }

  @media only screen and (max-width: 893px)   {
    .events_row{
        justify-content: center !important;
    }
    #footer-links{
        width: 100% !important;
        justify-content: space-between !important;
        
    }

    a, p, h4{
        z-index: 10 !important;
    }

    .event_card_description::before{
        content: "";
        position: absolute;
        width:70%;
        min-height: 70%;
        background: rgb(169,119,243, 0.3);
        background: linear-gradient(0deg, rgba(169,119,243,1) 0%, rgba(255,95,86,0.42) 100%);
        filter: blur(14vw) !important;
        border-radius: 30%;
        top: 0;
        z-index: 1 !important;
        left: -20%;
    }
   
    #footer_input_news{
        justify-content: start !important;
        grid-gap: 1px;
    }

    
    p#p-hero2{
        font-size: 2.2vw !important;
    }

    .event_card{
        height: 520px !important;

    }
    .event_card_date > h6 {
        font-size: 1.6vw !important;
    }
    .event_card_date > h3 {
        font-size: 2.4vw !important;
    }
  }

  @media only screen and (max-width: 1660px)   {
    
    #awards_section{
        flex-direction: column !important;
    }
   
    #awards_section > .w-50{
        width: 900% !important;
    }

    .hero-button-transparent{
        margin-right: 0 !important;
    }
    button.ml-3{
        margin-left: 1vw !important;
    }
    #awards_section > .justify-content-start{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }

    .event_card_description::before{
        filter: blur(8.5vw);
    }
  }

  @media only screen and (min-width: 1661px){
    a, p, h4{
        z-index: 10 !important;
    }
  }
  @media only screen and (max-width: 1200px){

    div#home-team-container{
        flex-direction: column !important;
    }

    .glowing-frame{
        height: 120px !important;
    }

    section > *{
        font-size: scale(1.3) !important;
    }

    h6#h6-member-name{
        font-size: 2.2vw !important;
    }

    h6#h6-member-title{
        font-size: 2vw !important;
        
    }
    p#p-member-description{
        font-size: 1.3vw !important;
    }

    button.hero-button-transparent{
        font-size: 1.8vw !important;
        font-weight: 500 !important;
    }
    button.hero-button-purple{
        font-size: 1.8vw !important;
        font-weight: 500 !important;
    }
    button.ml-3{
        margin-left: 2vw !important;
    }
    p#p-hero2{
        font-size: 1.8vw !important;
    }

    p#p-hero3{
        font-size: 1.4vw !important;
    }

     a, p, h4{
        z-index: 10 !important;
     }

    button.hero-tag-purple{
        font-size: 1.5vw !important;
    }

    #projects_tags > .w-50{
        width: 75% !important;
    }

  
  }

  @media only screen and (max-width: 500px){
  
    /* button.hero-button-transparent{
        font-size: 3vw !important;
        font-weight: 500 !important;
    } */
    .event_card_body{
        width: 80% !important;
    }
    .event_card_date > h6 {
        font-size: 2vw !important;
    }
    .event_card_date > h3 {
        font-size: 3vw !important;
    }

    .header-button{
        font-size: 2.3VW !important;
    }
  }


  .eva-goals-icon{
      margin-right: 1vw;
  }

  #project_picture{
    background:transparent;
    max-width: 100%;
    height: auto;
    border-radius: 0%;
    
  }
  
  .eva-home-container {
    margin-top: 25px;
    background-color: black;
    font-size: 20px;
  }

  .eva-about-section-container{
    margin-top: 40px;
    margin-bottom: 100px;
   
  }
  
  
  .PCB-bannerImage-container {
    position: absolute;
    top: 2750px;
    right: 200px;
    z-index: -2;
    max-width: 800px;
  } 
  
  
  .eva-home-image-container {
    position: relative;
    max-width: 500px;
    display: flex;
    background: transparent !important;
    justify-content: center;
    align-items: center;
    border-radius: 100px !important;
    height: 100%;
  }

  .eva-team-image-container {
    float: right;
    border:3px;
    
  }
  
  
  .eva-structure-image-container {
      max-width: 370px;
      float: right;
      height: 100%;
      background: none;
      margin-left: 15; 
      position: relative;
      margin-top: 40px;
  
  }
  
  .eva-icon {
    max-width: 75px;
    float: left;
  }
  
  .eva-home-text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .eva-primary-heading {
    font-size:60px;
    color: #ffffff;
    max-width: 600px;
    text-shadow: none !important;
    
  }
  
  .eva-title {
    font-size: 30px;
    color:#FFFFFF;
    margin-bottom: 15px;
  }
  
  .eva-box-heading{
    font-size: 25px;
    color: #FFFFFF;
    max-width: 600px;
    margin-bottom: 7px;
  }
  
  .eva-box-paragraph{
    font-size: 15px;
    color: #FFFFFF;
    max-width: 600px;
  }
  
  .eva-primary-heading-gradient {
    background: linear-gradient(30deg, #FF5F56, #855FBE);
    background-clip: text;
    -webkit-background-clip: text; /* For Safari and older versions of Chrome */
    color: transparent;
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
    
  }
  .eva-secondary-heading {
    font-size: 40px;
    max-width: 500px;
    color: #ffffff;
    margin: 1.5rem 0rem;
    text-align: left;
  }
  .eva-paragraph{
    font-size: 25px;
    color: #848290;
  }
  
  .eva-primary-heading-x{
    display: inline-block !important;
    vertical-align: top !important;
    font-size:50px !important;
    color: #ffffff !important;
    max-width: 600px !important;
    text-shadow: none !important;
  }
  .eva-primary-heading-x-gradient {
    background: linear-gradient(30deg, #FF5F56, #855FBE) !important;
    background-clip: text !important;
    -webkit-background-clip: text !important; /* For Safari and older versions of Chrome */
    color: transparent !important;
    font-size: 50px !important;
    font-weight: bold !important;
    margin-bottom: 10px !important;
    text-shadow: none !important;
    
  }
  .eva-paragraph-1 {
    font-size: 25px;
    max-width: 500px;
    color: #ffffff;
    text-align: left;
    
  }
  .eva-paragraph-2 {
    font-size: 25px;
    max-width: 430px;
    color: #ffffff;
    margin: 2.5rem 0rem;
    margin-top: 60px;
    margin-bottom: 5%;
    
  }
  .eva-paragraph-3 {
    font-size: 25px;
    max-width: 500px;
    color: #ffffff;
    margin: 1.5rem 0rem;
  }
  
   .wrap {
    margin-top: 25px;
    position: relative;
    padding: 0.9rem 1.75rem;
    background-color: #13111A;
    font-size: 20px;
    font-weight: 500;
    transition: 0.2s;
    color: #FFFFFF;
    margin-left: 60px;
    margin-right: 60px;
  }
  
  .eva-box {
  
    position: relative;
    background-color: #13111A;
    border-radius: 25px;
    border-color: #484848;
    border-style:solid;
    font-size: 20px;
    font-weight: 500;
    transition: 0.2s;
   
  }

  .eva-box-home{
    margin-top: 25px;
    position: relative;
    padding: 0.9rem 1.75rem;
    background-color: black;
    font-size: 20px;
    font-weight: 500;
    transition: 0.2s;
    margin-left: 60px;
    margin-right: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 100% !important;
  }

  .eva-text-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .eva-structure-box{

    margin-top: 45px;
    position: relative;
    padding: 0.9rem 1.75rem;
    background-color: black;
    margin-left: 60px;
    margin-right: 60px;

  }
  
  .eva-home-image-container:before {
    content:"";
    position: absolute;
    inset: 0;
    transform: translate3d(0,0,-1px);
    background: conic-gradient(from 90deg at 40% -25%,#855FBE ,#FF5F56 );
    filter: blur(25px);
    clip-path: polygon(-100vmax -100vmax,100vmax -100vmax,100vmax 100vmax,-100vmax 100vmax,-100vmax -100vmax,0 0,0 100%,100% 100%,100% 0,0 0);
  }
  
  .eva-column-2{
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .shadow::before {
    content:"";
    position: absolute !important;
    inset: 0 !important;
    transform: translate3d(0,0,-1px) !important;
    background: conic-gradient(from 90deg at 20% -25%,#49ADAF, #3D818A ) !important;
    filter: blur(25px) !important;
    clip-path: polygon(-100vmax -100vmax,100vmax -100vmax,100vmax 100vmax,-100vmax 100vmax,-100vmax -100vmax,0 0,0 100%,100% 100%,100% 0,0 0) !important;
  }
  
  .shadow2::before{
      content: ''!important;
      position: absolute!important;
      top: -10%!important;
      left: -10%!important;
      right: -10%!important;
      bottom: -10%!important;
      background: rgb(169,119,243, 0.3)!important;
      background: linear-gradient(0deg, rgba(169,119,243,1) 0%, rgba(255,95,86,0.27) 100%) !important;
      filter: blur(50px)!important;
      z-index: 0!important; /* Ensure the pseudo-element is below the box */
      border-radius: 10%; /* Optional: adds rounded corners to the glow */
  }

  .eva-work-section-wrapper {
    margin-top: 7rem;
  }
  .eva-work-section-top p {
    text-align: center;
    max-width: 600px !important;
  }
  .eva-work-section-top h1 {
    max-width: 700px !important;
  }
  .eva-work-section-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .eva-work-section-info {
    width: 280px;
    min-height: 350px;
    background-color: #1D1A27;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 1rem;
    color: #505050;
    margin: 1rem 2rem;
  }
  
  .eva-work-section-bottom {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  

  .eva-wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .eva-flex-container{
    width:100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top:80px;
  }

  .eva-flex-box{
    width:200px;
    height: 200px;
    background-color:#13111A;
    color:white;
    margin: 10px;
    font-size: 20px;
  }


  .eva-flex-table {
    display: flex;
    width: 80%;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
  }

  .eva-column {
    flex: 1;
    padding: 20px;
    text-align: center;
  }

  .eva-left-column {
    background-color: #f0f0f0;
  }

  .eva-right-column {
    background-color: #e0e0e0;
  }

  .eva-center-column {
    flex: 2;
    overflow: hidden;
  }

  .column-h-100{
    height:  100% !important;
  }

  


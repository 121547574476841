html{
  width: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow: scroll;
}

.space-body{
  text-align: center;
  background-color: black;
  overflow: hidden !important;
  justify-content: center;
  align-items: center;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*::-webkit-scrollbar{
  display: none;
}

.text-purple{
  color: #531f7a;
}

.text-purple-white{
  color: #9f69c6;
}

.sp-header{
  background-color: #282c34;
  position: relative !important;
  height: fit-content;
  min-height: 92vh !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden !important;
  border: none;
  z-index: 3;
}

.sp-header::before{
  content: "";
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: #3E1293 !important;
  opacity: 75%;

}

.header-main-title{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: calc(20px + 10vmin);
  margin-bottom:  calc(5px + 2vmin);
  font-weight: 700 !important;
}

.header-second-title{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: calc(15px + 6vmin);
  font-weight: 600 !important;
}

.z2{
  z-index: 2;
}

.w-85{
  width: 85%;
}

.margin-bottom-5{
  margin-bottom: 9vh !important;
}

.margin-top-5{
  margin-top: 3vh !important;
}


a{
  all: unset !important;
}
a, p, h4{
  z-index: 10 !important;
}
a:hover{
  cursor:  pointer !important;
}

/* @media only screen and (max-width: 1200px)   {




 
} */
:root{
  --broder-color: transparent;
}
.App {
  text-align: center;
  background-color: black;
  overflow: hidden !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.base-image{
  width: 100% !important;
  height: auto !important;
  object-fit: contain !important;
}
.color-white{
  color: white !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  position: relative;
  height: fit-content;
  height: 87vh;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background: url("./static/images/backgrounds/vodafone.JPG") center center;
  background-size: cover;
  overflow: hidden !important;
  /* overflow-y: hidden !important; */
  /* animation: forwards steps(100) 1s infinite; */
  border: none;
}

.App-header::-webkit-scrollbar{
  display: none;
}
#bg-black-blue{
  background: rgb(2,0,32);
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(135,96,193,1) 100%);
}
/* .App-header::after{
  content: "";
  display: block;
  position: absolute;
  background: url(static/images/separators/bg-section-cta-bottom-inside.svg) no-repeat 50% 0;
  background-size: contain;
  width: 100%;
  height: 40px;
  z-index: 100;
  bottom: 0 !important;
  border: none;
} */
#bg-white{
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 0px;
  bottom: 0px;
  border: none;
}
.App-link {
  color: #61dafb;
}

.title-logo{
  z-index: 10;
}

.title-logo#logo-upb{
  position: absolute;
  top:70%;
  width: 150px !important;
  opacity: 0.8;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes anim-ss { 0% {background-position: 0%;} 100% {background-position: 100%;} }

.main-logo{
  width: 30vmax;
  display: block;
  margin: auto auto;
  margin-bottom: 5vmax;
}
section{
  position: relative;
  overflow: visible;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

#bg-corner-left{
  position: absolute;
  top: -20%;
  left:-15%;
  width: 35% !important;
  opacity: 50%;
  overflow: hidden;
  z-index: 1;
  filter: invert(10%);
  -webkit-filter: invert(10%);
}

#bg-corner-rigth{
  margin: 0 !important;
  position: absolute;
  top:-30%;
  right:-30%;
  opacity: 50%;

  width: 67% !important;
  overflow: hidden;
  z-index: 1 !important;
  transform: rotate(90deg);
  filter: invert(10%);
  -webkit-filter: invert(10%);
}

.overlay-header-bg{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background: rgba(139,78,214, 0.8);
  background: radial-gradient(circle, rgba(139,78,214,0.8) 12%, rgba(69,14,136,0.9) 73%);
  
}

.h1-section-title{
  font-size: 60px;
  text-align: center !important;
  color: #4d306e !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 700 !important;
  text-decoration: none;
  text-shadow: none !important;
}

.outer {
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
}
.outer > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}
.outer .below {
  z-index: 1;
  background-color: aqua;
}
.outer .top {
  z-index: 2;
  background-color: brown;
}

.About{
  background: white;
  background-size: cover;
  background-attachment: fixed;
  overflow: scroll;
}
.h1-sectiontitle{
  font-size: 58px !important;
  text-shadow: 2px 2px rgba(121,80,171, 0.4) !important;
  
}

.about-row{
  height: fit-content !important;
}
.about-p{
  font-size: 23px;
  font-family:arial, verdana, sans-serif;
  font-weight: 400;
  line-height: 36px;
}
.About::-webkit-scrollbar {
  /* display: none; */
}

.img_about{
  aspect-ratio: 1/1 !important;
  width: 50%;
  box-shadow: 5px 10px 10px 5px;
}

.sponsors{
  width: 75%;
  aspect-ratio: 1/1;
  object-fit: contain;
  transition: linear 0.5s;
}

.sponsors:hover{
  scale: 1.2;
  cursor: pointer;
}

.card-white{
  aspect-ratio: 1/1;
  background: rgba(204, 204, 204, 0.8) !important;
  border-radius: 10%;
  box-shadow:
  0 2.8px 2.2px rgba(255, 255, 255, 0.034),
  0 6.7px 5.3px rgba(255, 255, 255, 0.048),
  0 12.5px 10px rgba(255, 255, 255, 0.06),
  0 22.3px 17.9px rgba(255, 255, 255, 0.072),
  0 41.8px 33.4px rgba(255, 255, 255, 0.086),
  0 12px 9px rgba(255, 255, 255, 0.12)
}

.project-pane{
  position: relative;
  aspect-ratio: 1/1;
  object-fit: contain;
}
.rounded{
  border-radius: 50px !important;
}
.overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(52,7,89, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
 
}

.project-image-pane{
  position: relative;
}

#img-projects{
  width: 100%;
  aspect-ratio: 1/1;

}

.project-image-pane:hover .overlay{
  opacity: 1;
}

#small_h1_underline{
  font-size: 1.5rem;
  font-weight: 800;
  color: #ff7149;
}

.dotted-vertically-carousel{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 11px;
  max-height: 80%;
}

.check-point{
  width: 11px;
  height: 11px;
  left: 5px;
  border: solid 1px grey ;
  border-radius: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;

}

.check-point-tail{
  width: 1px;
  height: 65px;
  border: dotted 1px grey ;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
#About-Images{
  height: 100%;
  /* margin-left: 13.45px; */
  object-fit: contain;
}
.about_images{
  height: 520px;
  aspect-ratio: 2/3;

}

.title-with-icon {
  position: relative;
  padding-left: 40px;
  width: 100%;
  min-height: 70px;
  padding-right: 40px;
  margin-top: -7px;
  margin-bottom: 42px;
}

.title-with-icon h3{
  min-height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #4d306e !important;
  text-align: left;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 700;
}

.title-with-icon h3:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  overflow: hidden;
  background-image: -webkit-linear-gradient(left, rgba(116, 105, 129, 0.1) 0%, #746981 100%);
  background-image: -o-linear-gradient(left, rgba(116, 105, 129, 0.1) 0%, #746981 100%);
  background-image: linear-gradient(to right, rgba(116, 105, 129, 0.1) 0%, #746981 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a746981', endColorstr='#ff746981', GradientType=1);
}

#sponsor-row{
  max-width: max-content;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
}

h2.color-orange{
  color: rgba(52,7,89, 1);
  font-weight: 700;
}

@media screen and (max-width: 1479px) {

  #About-content{
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
  }

  #About-Images{
    margin-top: 0px;
    width: 75%;
  }

  #About-Text{
    width: 100% !important;
    margin-top: 20px;
    text-transform: scale(0.8);
  }

  .h1_low_res{
    font-size: 60px !important;
  }

  h2.h1_low_res{
    font-size: 37px !important;
  }

  .about_images{
    width: 460px;
    aspect-ratio: 2/3 !important;
  }

  .title-with-icon  {
    width: 100%;
  }

  .mb-4.h3_low_res{
    margin-top: 15px;
  }
}

@media screen and (max-width: 1300px) {
  #sponsor-row-1{
    flex-direction: column !important;
  }
}

@media screen and (max-width: 1200px) {
  .display-10{
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1024px) {
  .display-10{
    font-size: 16px !important;
  }

  #projects-row{
    flex-direction: column !important;
  }
}

@media screen and (max-width: 720px){
  #sponsor-row-2{
    flex-direction: column !important;
  }
  .about_images{
    width: 360px;
    height: 440px;
    aspect-ratio: 2/3 !important;
  }

  .h1_low_res{
    font-size: 37px !important ;
  }
}
.new_team_page_header{
    background-color: #282c34;
    position: relative;
    height: fit-content;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background: url("../images/backgrounds/vodafone.JPG") center center;
    background-size: cover;
    overflow: hidden !important;
    /* overflow-y: hidden !important; */
    /* animation: forwards steps(100) 1s infinite; */
    border: none;
}

.overlay-header-team{
    width: 100%;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

.overlay-header-row{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
}

.overlay_header_column_image{
    width: 50%;
    position: relative;
    display: block;
}

.overlay_header_column{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.overlay_header_column_image  img{
    max-width: 100%; 
    height: 100%;
}

.overlay-header-team_leader{
    width: 100%;
    height: 100% !;
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-header-team_leader{
    width: 20%;

}

#cover_div_size{
    object-fit: cover !important;
}

#new-team_leader{
    width: 100%!important;
}

.our-team{
    text-align: center;
    overflow: hidden;
    position: relative;
    background: #5617b0;
    border-radius: 10px 10px;
    max-width: 100%;
}

.card_flip{
    
    -webkit-perspective: 150rem;
            perspective: 150rem;
    -moz-perspective: 150rem;
    position: absolute;
    width: 90%;
    bottom: 20%;
    right: -60%;
    height: 50%;
}

.card__side {
    height: 50%;
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;
    position: absolute;
    top: 0;
    width: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 3px;
    overflow: hidden;
    -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
            box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  }

  .card_flip:hover .card__side--front-1 {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
  
  .card_flip:hover .card__side--back {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  
.expanding-possibilties{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 20px 28px;
    background: #EDECEC;
    

}

.card__side--back {
    width: 100%;
    height: 100%;
    padding: 20px 28px;
    background: #EDECEC;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.card_flip:hover .expanding-possibilties {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
}

.card_flip:hover .card__side--back {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
}

#leader_card_about_us{
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-size: 1.3rem;
    font-weight: 900;
    color: black;
}

#leader_card_text{
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-size: 2.3rem;
    font-style: italic;
    font-weight: 700;
    color: black;
}

li#leader_card_li{
    text-transform: lowercase;
    font-family: "Roboto", sans-serif;
    font-size: 0.9rem;
    font-style: italic;
    font-weight: 800;
    color: black;
}

.card__side{
    box-shadow: rgba(255, 255, 255, 0.4) 0px 8px 24px;
}

.left-shadow-gradient{
    width: 100%;
    height: 20%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgb(2,0,36);
    background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 10%, rgba(255,255,255,0) 100%);
}

.rigth-shadow-gradient{
    width: 100%;
    height: 20%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
}

.div-role-icon{
    position: absolute;
    right: 5%;
    top:5%;
    width: 25%;
    aspect-ratio: 1/1;
    display: block;
}

.page-title-border{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid WHITE;
    border-top: 3px solid WHITE;
}

.page-title-border > h1{
    font-size: 6vmin;
    padding: 10px 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
}

.counter {
    display: flex;
  
    
  }

  .counter-item {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    background: var(--blue);
    padding: 3px;
    
  }

  span.label {
    font-size: 0.6rem;
    color: var(--blue-dark);
    width: 100%;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    margin-top: 0.1rem;
    background: var(--white);
  }

  span.value {
    width: 100%;
    padding: 0 0.5rem;
    font-size: 1rem;
    color: var(--white);
    text-align: center;
  }

  @media (max-width: 500px) {
    span.value {
      font-size: 1rem;
    }
    span.label {
      font-size: 0.6rem !important;

    }

  }
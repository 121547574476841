.App#vote-app{
    background: url("../images/backgrounds/bg_vote.jpg") center center;
    background-size: cover;

    height: 100vh;
    overflow-y: scroll !important;

}
.App#vote-app::-webkit-scrollbar{
    display: none;
  }

.App-header#app-header-vote {
    scroll-behavior: smooth;
    height: fit-content;
    background: transparent;
    overflow-y: scroll;
}

#vote-list-section{
    position: relative;
    background: transparent !important;
    z-index: 100;
}


li#concil-resposibilities{
    font-size: 21px;
    text-align: left ;
    color: #ffffff !important;  
    z-index: 1000;  
}

span#vote-span-title{
    font-size: 50px;
    font-weight: 900;
}

  a { 
    margin: auto;
    text-decoration: none !important;

  }

  button,  button:focus,  button:hover,  button:active {
    outline: none !important;
    box-shadow:none !important;
    text-decoration: none !important;
  
  }
  .logo{
    width: 35px; 
    height: 35px;
    position: absolute;
    left: 50%;
    top:25%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }
  #bg-black{
    height: 8vh;
    background-color: black !important;
    box-shadow: rgba(255, 255, 255, 0.3) 0px 1px 2px 0px, rgba(255, 255, 255, 0.15) 0px 2px 6px 2px;
  }

  /* React Navbar.Toggle style */
  .navbar-toggler-icon{
    background-image: url("./../images/menu_white.png");
  }

  .navbar-toggler,  .navbar-toggler:focus,  .navbar-toggler:hover,  .navbar-toggler:active {
    outline: none;
    box-shadow:none;
}

/*Offcanvas extra style*/
.offcanvas{
  background: rgba(33, 37, 41, 0.9);
  
}
.offcanvas-header{
  background: rgba(33, 37, 41, 1);
}
.offcanvas-body{
  overflow: scroll;
}
.offcanvas-body::-webkit-scrollbar{
  display: none;
}

.nav-logo{
  width: 30%;

}

.navbar{
  z-index: 10;

}

/* Offcanvas close button */
.btn-close{
  filter: invert(100%);  
  margin-right: 2% !important;
}

.btn-close,  .btn-close:focus,  .btn-close:hover,  .btn-close:active {
  outline: none;
  box-shadow:none;

}

h2.font-verdana{
  font-family:  Verdana, sans-serif;
  font-weight: 100 !important;

}
/* Nav Dropdown style*/
#offcanvasNavbarDropdown-expand-false{
  text-align: center;
}

.dropdown-item{
  text-align: center;
  color: white;
}

.dropdown-menu{
  background-color: rgb(33, 37, 41);
  border: none;
}


.dropdown-item,  .dropdown-item:focus,  .dropdown-item:hover,  .dropdown-item:active {
  outline: none;
  box-shadow:none;
  background-color: rgb(33, 37, 41);
  display: flex;
  justify-content: center;
  align-items: center;

}

/* Buttons effects */

h3 {
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 20px;
  color: white;
}

.btn {
  height: 60px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.8s ease;
  position: relative;
  padding: 0;
  margin: auto 40px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.btn::before,
.btn::after {
  position: absolute !important;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
}

.btn4 {
  overflow: hidden;
}

.btn4:after {
  left: -100%;
  clip-path: polygon(10% 0, 70% 0, 90% 100%, 30% 100%);
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 300ms ease;
  opacity: 80%;
}

.btn4:hover::after {
  left: 100%;
}
.btn4:hover{
  opacity: 80%;
  background-color: #554D74;
  color: rgba(50, 50, 93, 0.25) !important;
  transition: all 350ms ease;
}

.nav-footer-contacts{
  display: flex;
  justify-content: center;
  width: 100%;
}
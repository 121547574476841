/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Reem Kufi", sans-serif !important;
  }
  img {
    max-width: 100%;
    height: auto;
    border-radius: 20px;
    
  }

  body {
    max-width: 100%;
    overflow-x: hidden;
    background-color: black;
  } */

  .luna-box-home{
    margin-top: 25px;
    position: relative;
    background-color: black;
    font-size: 20px;
    font-weight: 500;
    text-shadow: none !important;
   
  }

  .luna-home-image-container {
    max-width: 400px;
    float: right;
    margin-left: 60px;
    margin-top:60px;
   
  }

  .luna-home-text-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-decoration: none !important;
    text-shadow: none !important;
  

  }

  .luna-title{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: none !important;
  }

  .luna-text-box{
    flex:1;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    text-shadow: none !important;
  }
  .luna-primary-heading {
    font-size: 60px;
    color: white;
    max-width: 600px;
    font-weight: 600;
    text-shadow: none !important;
  }

  .luna-third-heading{
    font-size: 25px;
    color:white;
    display: flex;
    font-weight: 600;
    text-shadow: none !important;

  }

  .luna-secondary-heading{
    font-weight: 600;
    font-size: 40px;
    max-width: 500px;
    color: #ffffff;
    margin: 1.5rem 0rem;
    text-align: left;
    text-shadow: none !important;
  }

  .luna-paragraph-1{
    font-size: 20px;
    color: #848290;
    max-width: 500px;
    margin: 1.5rem 0rem;
    text-align: left;
    text-shadow: none !important;
    
  }

  .luna-work-section-top h1{
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-shadow: none !important;
  }



  .luna-primary-heading-gradient {
    background: linear-gradient(30deg, #49ADAF, #855FBE) !important;
    background-clip: text !important;
    -webkit-background-clip: text !important; /* For Safari and older versions of Chrome */
    color: transparent !important;
    font-size: 60px !important;
    margin-bottom: 10px;
    text-shadow: none !important;
    
  }
.luna-icon{
    max-width: 75px;
    float: left;
    color:white
}


.luna-team-history-primary-heading {
    font-size: 60px;
    color: white;
    font-weight: 600;
    justify-content: center;
  }

  
  .luna-team-history-primary-heading-gradient {
    background: linear-gradient(30deg, #49ADAF, #855FBE);
    background-clip: text;
    -webkit-background-clip: text; /* For Safari and older versions of Chrome */
    color: transparent;
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 60px;
    
  }

  .luna-team-history-box{
    background-color: #1D1A27;
    display: flex;
    justify-content: space-between;
    padding: 40px;
    width: 100%;
    border-radius: 20px;
    border-style: solid;
    border-color: #4c4c4c;
  }

  .luna-team-history-text-in-box{
    margin: 0;
    font-size: 25px;
    color: white;
    margin-right: 40px;
    margin-top: 80px;
  }

  .luna-team-history-image-in-box{
    height: 500px;
  }

  .luna-work-section-wrapper {
    margin-top: 7rem;
    
  }

  .luna-work-section-bottom {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
   
  }

  .luna-work-section-info {
    width: 280px;
    min-height: 350px;
    background-color: #1D1A27;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 1rem;
    color: #505050;
    margin: 1rem 2rem;
  }

  .luna-electronics-heading{
    background: linear-gradient(30deg, #49ADAF, #855FBE);
    background-clip: text;
    -webkit-background-clip: text; /* For Safari and older versions of Chrome */
    color: transparent;
    font-size: 40px;
    font-weight: bold;
    justify-content: left;
  }

  .luna-flight-stages-title{
    background: linear-gradient(30deg, #49ADAF, #855FBE) !important;
    background-clip: text !important;
    -webkit-background-clip: text !important; /* For Safari and older versions of Chrome */
    color: transparent !important;
    font-size: 60px !important;
    font-weight: bold;
    margin-bottom: 140px;
    margin-top: 80px;
    text-shadow: none !important;
    
  }

  .luna-flight-stages-image{
    max-width: 100%;
    size: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 140px;
  }


    .rocket-table {
        display: flex;
        flex-direction: column;
      }
      
      .rocket-row {
        display: flex;
        margin-bottom: 10px; /* Adjust the margin as needed */
      }
      
      .rocket-column {
        flex: 1;
      }

      .rocket-column-left {
        background-color: #1D1A27;
        border-radius: 20px;
        border-style: solid;
        border-color: #484848;
        margin: auto;
        
      }
      
      .rocket-column img {
        display: block;
        margin: 0 auto;
        max-width: 100%; /* Ensure the image doesn't exceed the container width */
      }

      .luna-component-title{
        font-size: 30px;
        color: white;
        justify-content: left;
        margin:10%;
    
      }

      .luna-component-description{
        font-size: 20px;
        color: white;
        justify-content: left;
        margin: 10%;
        
      }

      .luna-component-image{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .gradient:before {
        content:"";
        position: absolute;
        inset: 0;
        transform: translate3d(0,0,-1px);
        background: conic-gradient(from 90deg at 40% -25%,#855FBE , #49ADAF );
        filter: blur(25px);
       clip-path: polygon(-100vmax -100vmax,100vmax -100vmax,100vmax 100vmax,-100vmax 100vmax,-100vmax -100vmax,0 0,0 100%,100% 100%,100% 0,0 0);
      }
#about-us-header{
    background: url("../images/AboutUS/header-bg.png") center center ;
    background-size: cover;
}
#campus_image_div{
    position: relative;
    overflow:visible;

}
#campus_image_div::before{
    content: "";
    position: absolute;
    opacity: 60%;
    width:70vw;
    aspect-ratio: 9/4;
    overflow:visible;
    background: rgb(169,119,243, 0.3);
    background: linear-gradient(180deg, rgba(169,119,243,1) 0%, rgba(255,95,86,0.27) 100%);
    filter: blur(120px);
    border-radius: 100%;
    /* background: url("../images/AboutUS/glowing_bakcground.png") center center ;
    background-size: cover; */
    rotate: 180;
    top: -3vh;
    z-index: 1;
    right: -14vw;
}

#euroc_image{
    position: relative;
    overflow:visible;
}


#euroc_image::before
{
    content:"";
    position: absolute;
    opacity: 80%;
    width:100%;
    aspect-ratio: 1/1;
    overflow:visible;
    background: url("../images/AboutUS/euroc_background.png") center center ;
    background-size: cover;
    rotate: 180;
    bottom: -6vh;
    z-index: 1;
    left:0;
}

#black-shadow{
    box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.1),
              /* 2. shadow ring 👇 */
              0 0 0 1px hsla(230, 13%, 9%, 0.075),
              /* 3. multiple soft shadows 👇 */
              0 0.3px 0.4px hsla(230, 13%, 9%, 0.02),
              0 0.9px 1.5px hsla(230, 13%, 9%, 0.045),
              0 3.5px 6px hsla(230, 13%, 9%, 0.09);
    --color-bg-light: hsla(240, 7%, 19%, 0.421);
    background: var(--color-bg-light);
}

.qube2SpaceMain{
    background: url("../images/Qube2space/qube2space.png") center center;
}

a#qube2sapce{
    outline: none;
    text-decoration: none !important;
    color: rgb(139, 78, 214) !important;

}

#q2s-title{
    z-index: 10;
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: center;
}
.css-1v6o2d9-MuiTimelineItem-root#blanktime{
    height: 0px !important;
}

.w75{
    width: 75%;
}
.imagetimeline{
    width: 25rem!important;
    height: 20rem !important;
    margin-top: 7rem !important;
}

.imagetimeline2{
    width: 55% !important;
    height: 25rem !important;
    margin-top: 5rem !important;
}

h1#blend-mode-title{
   text-align: center;
   font-size: 85px;
   font-weight: 700;
   letter-spacing: 1px;
}

h2#blend-mode-title{
    text-align: center;
    font-size: 40px !important;
    font-weight: 700;
    letter-spacing: 1px;
 }
 
.pdf-view{
    z-index: 20;
    background: transparent;
    overflow: scroll;
}
.react-pdf__Page__textContent {
    display: none !important;
    margin: 0;
}
.textLayer{
    display: none !important;
    margin: 0;
}

.react-pdf__Page__annotations {
    display: none !important;
}

.annotationLayer{
    display: none !important;
}

.bt-rules{
    background: transparent;
    margin-top: 10px;
    z-index: 10;
    font-size: 40px;
    font-weight: 700;
    width: 300px;
    color: rgba(139,78,214) !important;
    border-radius: 10px;
    transition: ease-in-out 0.6s;
    outline: none;
    border: none;

}
.body2{
    border: 1px solid white !important;
    width: 50% !important;
    padding: .5rem;
    border-radius: 15px;
}
.bt-rules:hover{
    transform: scale(1.2);
    transition: ease-in-out 0.6s;
    cursor: pointer;
    outline: none;
    border: none;
}

.bt-rules > a > .btn4{
    background-color: #f1f1f1 !important;
    color: rgb(139, 78, 214) !important;
}
.bt-rules > a > .btn4 > h3{
    color: rgb(139, 78, 214) !important;
    font-weight: 700;

}
.aboutimg{
    height: auto;
    width: 30% !important;
}
@media screen and (max-width: 1200px){
    h1#blend-mode-title{
        font-size: 55px !important;
    }

    h2#blend-mode-title{
        font-size: 30px !important;
    }

    .w-25#q2s-title{
        width: 75%;
    }
    .onsm100 {
        width: 100% !important;
        padding: 0 .3rem;
    }
    .body2{
       
        width: 95% !important;
        
    }
}

@media screen and (max-width: 642px){

    h1#blend-mode-title{
        font-size: 35px !important;
    }

    h2#blend-mode-title{
        font-size: 20px !important;
    }

    #buttons-row{
        margin-top: 20px !important;
        flex-direction: column !important;
        align-items: center;
    }

    .bt-rules{
        width: 200px !important;
    }

}

@media (min-width: 1600px) {
    .d-xxl-flex {
      display: flex !important;
    }
    .justify-content-xl-between{
        justify-content: space-between;
    }
    /* add other classes as needed */
  }


section {
    background-color: black;
}

.footer-container{
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.links-row{
    display: flex;
    justify-content: space-around;
    width: 200px;
}

.social-img-footer{
    aspect-ratio: 1;
    width: 100%;
    max-width: 30px;

}

.social-img-footer:hover, .social-img-footer:focus {
    color:#ff7149 !important;
    cursor: pointer;
    outline: none;
    border: 0;
}


body {
    font-family: 'Alegreya Sans', sans-serif;
}

/* Sticky footer position and size
-------------------------------------------------- */

.footer {

  width: 100%;
  /* Set the fixed height of the footer here */
  height: fit-content;
  z-index: 10;
}

/* Taller footer on small screens */


/* Sticky footer style and color
-------------------------------------------------- */
footer {
  padding-top: 30px;
  background-color: black;
  width: 100%;
  color: #bbb;
  display: flex;
  justify-content: center;
  z-index: 1000 !important;

}

.container1{
  width: 100% !important;
  display: flex;
  justify-content: center ;
  z-index: 1000 !important;

}



footer a {
  color: #999;
  text-decoration:none;
}
.form-group{
    margin-bottom: 10px;
}
footer a:hover, footer a:focus {
  color:white;
  text-decoration:none;
  border-bottom:1px dotted #999;
}


footer .form-control {
    background-color: #1f2022;
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
    border: none;
    resize: none;
    color: #d1d2d2;
    padding: 0.7em 1em;
}

.nav-link svg:hover, .nav-link svg:active {
  color: white;
}

h5, h6{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 1000;
}

@media only screen and ((max-width: 767px)){
  .row{
    justify-content: center;
  }
  
}

@media only screen and ((max-width: 380px)){
  .row{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  col-md-6{
  padding: 0 !important;

  }

}

hr{
  width: 60%;
  margin-left: 20% ;
}
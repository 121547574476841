
 
img{
    alig-self: center;
    aspect-ration: 1/1;
    width: 100%;
}

 
 .container {
    width: 300px;
    height: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    background: transparent;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
 }
 
 .box {
    position: absolute;
    width: 300px;
    height: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: transparent;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border-radius: 3px;
    overflow: hidden;
    -webkit-transition: -webkit-box-shadow ease 1s;
    transition: -webkit-box-shadow ease 1s;
    transition: box-shadow ease 1s;
    transition: box-shadow ease 1s, -webkit-box-shadow ease 1s;

    
 }
 
 .icon {
    width: 27.5%;
    margin-top:0;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 0px;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
    -webkit-transition-delay: 0.32s;
            transition-delay: 0.32s;
    will-change: transform;
 }
 
 .icon_bg {
    width: 110px;
    height: 110px;

    /* border-style: solid;
    border-width: 1x;
    border-image-source: linear-gradient(to left, #FF4A40, #6B0FF6);
    border-image-width: 1px;
    -webkit-border-image-slice: 2;
    border-image-slice: 1; */
    border-radius: 100%;
    -webkit-box-shadow: 0 3px 4px 0 rgba(212, 185, 110, 0.25);
    box-shadow: 0 3px 4px 0 rgba(212, 185, 110, 0.25);

    /* From https://css.glass */
    /* From https://css.glass */
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.1px);
    -webkit-backdrop-filter: blur(5.1px);
    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
    -webkit-transition-delay: 0.32s;
    transition-delay: 0.32s;
    will-change: transform;
 }
 
 .title {
    position: relative;
    color: #fff;
 }
 
 .super-box:hover .box {
 /*   border: 1px solid rgba(225, 227, 232, 0.18); */
    -webkit-box-shadow: 0 3px 4px 0 rgba(44, 71, 146, 0.25);
    box-shadow: 0 3px 4px 0 rgba(44, 71, 146, 0.25);
    -webkit-transition-delay: 0.32s;
    transition-delay: 0.32s;

 }
 
 .super-box:hover .icon_bg {
    -webkit-transform: translateY(-32px) scale(6);
    transform: translateY(-32px) scale(6);
    filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);

 }
 
 .super-box:hover .icon {
     -webkit-transform: translateY(-48px) scale(1.4);
    transform: translateY(-48px) scale(1.4);
 }
 
 .super-box {
     width: 300px;
    height: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: transparent;
    align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
 }
 
 .text {
    position: relative;
    top: -32px;
    width: 200px;
    text-align: center;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    -webkit-transition-delay: 0.32s;
            transition-delay: 0.32s;
 }
 
 .super-box:hover .text {
    opacity: 0;
    -webkit-transform: translateY(-4px) scale(.8);
            transform: translateY(-4px) scale(.8);
   
 }
 
 .box::after {
    content: attr(data-content);
    position: absolute;
    width: 200px;
    top: 180px;
    opacity: 0;
    font-size: 13px;
    color: white;
    line-height: 20px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transition-delay: 0.32s;
            transition-delay: 0.32s;
    
 }
 
 .super-box:hover .box::after {
    opacity: 1;
 }

.dr {
    position: absolute;
    bottom: 16px; 
    right: 16px;
    width:100px;
}
 